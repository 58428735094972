// src/routes/AppRouter.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Mainheader from "../components/Mainheader";
import PageNotFound from "../pages/PageNotFound";
import PreSchoolDetails from '../pages/PreSchooLDetails'
import Footer from "../pages/Footer";
import VideoPage from "../pages/VideoPage";
import PicturePage from "../pages/PicturePage";
import Games from "../pages/Games";
import LearningToys from "../pages/LearningToys";
import CurriculumCombineBooks from "../pages/CurriculumCombineBooks";
import Printable from "../pages/Printable";
import VideoList from "../pages/VidoeList";
import WeeklyPlan from "../pages/WeeklyPlans";
import StarOfMonth from "../pages/StarOfMonth";

function AppRouter() {
    return (
        <Router>
            {/* Navigation Bar with Tailwind CSS styling */}
            <Mainheader />
            {/* Main content area */}
            <div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="preschool" element={<PreSchoolDetails />} />
                    <Route path="printable" element={<Printable />} />
                    <Route path="games" element={<Games />} />
                    <Route path="learning-toys" element={<LearningToys />} />
                    <Route path="curriculum" element={<CurriculumCombineBooks />} />
                    <Route path="videos" element={<VideoPage />} />
                    <Route path="youtube" element={<VideoList />} />
                    <Route path="pics" element={<PicturePage />} />
                    <Route path="weekly-plan" element={<WeeklyPlan />} />
                    <Route path="star-of-the-month" element={<StarOfMonth />} />
                    {/* Catch-all route for undefined paths */}
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </div>
            <Footer />
        </Router>
    );
}

export default AppRouter;
