import React from "react";

const EventCard = ({ event }) => {
    const handleDownload = async () => {
        try {
            const response = await fetch(event.image);
            if (!response.ok) throw new Error("Failed to fetch image");

            const blob = await response.blob();
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = event.alt || "student-image.jpg";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Download failed:", error);
        }
    };
    const handleView = () => {
        window.open(event.image, "_blank");
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: event.name,
                text: `Check this! My ${event.name} got Star of the Month at https://www.homeschoolingwithmbu.com/star-of-the-month   `,
            }).catch((error) => console.error("Sharing failed:", error));
        } else {
            alert("Sharing is not supported in this browser.");
        }
    };

    return (
        <li className="relative bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105">
            <img
                className="w-full h-auto rounded-md object-cover"
                src={event.image}
                alt={event.alt}
                decoding="async"
            />
            <div className="text-lg font-bold text-center mt-2 text-gray-900">
                {event.name}
            </div>
            <div className="text-sm text-center text-gray-600 mb-2">
                {new Date(event.date).toLocaleDateString("en-US", { year: "numeric", month: "long" })}
            </div>
            <div className="flex justify-center space-x-4 mt-2 mb-4">
                <button
                    onClick={handleDownload}
                    className="bg-blue-500 text-white px-4 py-1 rounded shadow-md hover:bg-blue-600"
                >
                    Download
                </button>
                <button
                    onClick={handleView}
                    className="bg-green-500 text-white px-4 py-1 rounded shadow-md hover:bg-green-600"
                >
                    View
                </button>
                <button
                    onClick={handleShare}
                    className="bg-purple-500 text-white px-4 py-1 rounded shadow-md hover:bg-purple-600"
                >
                    Share
                </button>
            </div>
        </li>
    );
};

export default EventCard;
