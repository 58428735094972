export const WeeklyPlanData = [
    {
        path: "https://drive.google.com/file/d/1eCKMY42spxYjVFepcuzJLos_vnzkiLY8/view",
        title: `Week 1`,
        weekNumber: '1',
        thumbnail: 'https://res.cloudinary.com/dgybxrkh2/image/upload/v1739214712/1_mgasek.jpg'
    },
    {
        path: "https://drive.google.com/file/d/1hy-yBBkCKBSi0LCgKUTt8at23SG4Ha0l/view",
        title: `Week 2`,
        weekNumber: '2',
        thumbnail: 'https://res.cloudinary.com/dgybxrkh2/image/upload/v1739214714/2_rnfpva.jpg'
    },
    {
        path: "https://drive.google.com/file/d/1jHG5O3BTEhTWNN7KvdcvhW5qgHv9djD-/view",
        title: `Week 3`,
        weekNumber: '3',
        thumbnail: 'https://res.cloudinary.com/dgybxrkh2/image/upload/v1739214715/3_kswa7q.jpg'
    },
    {
        path: "https://drive.google.com/file/d/1lg_adtpDROUDPj12W17IAlyYHyE6PRmj/view",
        title: `Week 4`,
        weekNumber: '4',
        thumbnail: 'https://res.cloudinary.com/dgybxrkh2/image/upload/v1739214716/4_vtudox.jpg'
    },
    {
        path: "https://drive.google.com/file/d/1nZUvg8qd0z5gy8CeKOnCQjMRYgW_HB2D/view",
        title: `Week 5`,
        weekNumber: '5',
        thumbnail: 'https://res.cloudinary.com/dgybxrkh2/image/upload/v1739214721/5_gzfsja.jpg'
    },
    {
        path: "https://drive.google.com/file/d/1bfntdHHfDFnGH5py2BnKoeyKJ8voXjf8/view",
        title: `Week 6`,
        weekNumber: '6',
        thumbnail: require("../images/books-cover.jpeg")
    },
]