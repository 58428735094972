export const StarOfMonthData = [
    {
        image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739212587/muhammad_bin_umer_fhh5it.jpg",
        name: "Muhammad bin Umer",
        date: "Jan, 2025"
    },
    {
        image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739212851/Muhammad_tahoor_xoeswv.jpg",
        name: "Muhammad Tahoor",
        date: "Jan, 2025"
    },
    {
        image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739212969/Muhammad_zohan_y6aocn.jpg",
        name: "Muhammad Zohan",
        date: "Jan, 2025"
    },
    {
        image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739213308/Muhammad_bin_Ali_kgtka6.jpg",
        name: "Muhammad bin Ali",
        date: "Jan, 2025"
    },
    {
        image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739213722/Ayesha_salman_zdhdht.jpg",
        name: "Ayesha Salman",
        date: "Jan, 2025"
    },
    {
        image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739214137/Abdul_hadi_k2khuw.jpg",
        name: "Abdul Hadi",
        date: "Jan, 2025"
    },
    {
        image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739214503/Fatima_Adil_gzfmce.jpg",
        name: "Fatima Adil",
        date: "Jan, 2025"
    },
]